import PropTypes from 'prop-types'
import { React } from 'react'
import WhiteLogo from '../../images/iom-logo-white.svg'
import MiRACLogo from '../../images/mirac-logo.png'
import { Colors, Typography } from '../../styles'
import { Col, Row } from './styles'

const Footer = () => {
  return (
    <Row>
      <Col width='15%'>
        <img src={WhiteLogo} style={{ width: '100%', display: 'block' }}/>
      </Col>
      <Col width='25%'>
        <img src={MiRACLogo} style={{ width: '100%', display: 'block' }}/>
      </Col>
      <Col width='30%' style={{ marginLeft: '1%' }}>
        <Typography.Header color={Colors.WHITE} size='25px' bottom='15px'>Migration Data Platform for Evidence-Based Regional Development (M-POWERD)</Typography.Header>
        <Typography.Header3 color={Colors.WHITE} size='14px' style={{ marginTop: '-20px', marginBottom: '20px' }}>IN SOUTH-EASTERN, EASTERN EUROPE AND CENTRAL ASIA (SEEECA)</Typography.Header3>
        <Typography.Body color={Colors.WHITE} size='18px' style={{ marginTop: '-10px' }}>in collaboration with World Data Lab</Typography.Body>
      </Col>
      <Col width='30%'>
        <Typography.Header3 color={Colors.WHITE} style={{ marginBottom: '10px' }}>CONTACT US:</Typography.Header3>
        <Typography.Body color={Colors.WHITE} style={{ marginBottom: '10px' }}>General inquiries: rovienna@iom.int</Typography.Body>
        <Typography.Body color={Colors.WHITE} style={{ marginBottom: '10px' }}>Telephone: +43 1 581 22 22</Typography.Body>
        <Typography.Body color={Colors.WHITE}>IOM Vienna - Regional Office for SEEECA<br/>Dampfschiffstrasse 4 / 10-11<br/>1030 Vienna, Austria</Typography.Body>
      </Col>
    </Row>
  )
}
Footer.propTypes = {
  country: PropTypes.string
}
export default Footer
