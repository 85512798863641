import { React, useRef } from 'react'
import Arrow from '../../images/down-arrow.svg'
import WorldMap from '../../images/world-map.svg'
import { Colors, Typography } from '../../styles'
import Footer from '../Footer'
import Header from '../Header'
import { Box, BoxRow1, BoxRow2, BoxText, Button, CountriesRow, KosovoButton, MetaContainer, RemittanceText } from './styles'

const Home = () => {
  const allCountries = ['Albania', 'Azerbaijan', 'Armenia', 'Belarus', 'Bosnia and Herzegovina', 'Georgia',
    'Kazakhstan', 'Kyrgyzstan', 'Montenegro', 'North Macedonia', 'Republic of Moldova', 'Russian Federation', 'Serbia',
    'Tajikistan', 'Turkey', 'Turkmenistan', 'Ukraine', 'Uzbekistan']

  const countriesRef = useRef(null)

  const toURL = (country) => { return ('/country/' + country.replace(/\s+/g, '-').toLowerCase()) }
  const hasLineBreak = (country) => { return (country === 'Bosnia and Herzegovina' || country === 'Republic of Moldova' || country === 'Russian Federation') }
  return (
    <>
      <Header country='' />
      <MetaContainer>
        <div>
          <img src={WorldMap} style={{ maxWidth: '94%', marginLeft: '3%', marginTop: '-3%' }}/>
          <BoxRow1>
            <Box color='#ADC8F2'>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid black' }}>
                <Typography.Header2 size='14px' style={{ letterSpacing: '0.2em', paddingBottom: '15px' }}>TOTAL MIGRANT STOCK</Typography.Header2>
                <Typography.Header2 size='14px' style={{ letterSpacing: '0.2em' }}>2019</Typography.Header2>
                <Typography.Header3 size='14px' style={{ marginTop: '0px' }}>IN WORLD</Typography.Header3>
                <Typography.Header style={{ marginBottom: '-5px' }}>304,428,068</Typography.Header>
              </BoxText>
              <BoxText bottom='5px'>
                <Typography.Header3 size='14px'>IN SEEECA</Typography.Header3>
                <Typography.Body size='9.6px'>(South-Eastern, Eastern Europe, and Central Asia)</Typography.Body>
                <Typography.Header style={{ marginBottom: '-5px' }}>30,722,915</Typography.Header>
                <Typography.Body size='14px' bottom='30px'>SEEECA accounts for 10.1% of the total worldwide migrant stock</Typography.Body>
              </BoxText>
            </Box>
            <Box color='#ADC8F2'>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid black' }}>
                <Typography.Header2 size='14px' style={{ letterSpacing: '0.2em', paddingBottom: '15px' }}>POPULATION</Typography.Header2>
                <Typography.Header2 size='14px' style={{ letterSpacing: '0.2em' }}>2019</Typography.Header2>
                <Typography.Header3 size='14px' style={{ marginTop: '0px' }}>IN WORLD</Typography.Header3>
                <Typography.Header style={{ marginBottom: '-5px' }}>7,713,468,205</Typography.Header>
              </BoxText>
              <BoxText bottom='5px'>
                <Typography.Header3 size='14px'>IN SEEECA</Typography.Header3>
                <Typography.Body size='9.6px'>(South-Eastern, Eastern Europe, and Central Asia)</Typography.Body>
                <Typography.Header style={{ marginBottom: '-5px' }}>390,742,096</Typography.Header>
                <Typography.Body size='14px' bottom='30px'>SEEECA accounts for 5.1% of the total worldwide population</Typography.Body>
              </BoxText>
            </Box>
            <Box color='#E7F4F3'>
              <RemittanceText bottom='16px' style={{ borderBottom: '1px solid black' }}>
                <Typography.Header2 size='14px' style={{ letterSpacing: '0.2em', paddingBottom: '15px' }}>REMITTANCE FLOWS</Typography.Header2>
                <Typography.Header2 size='14px' style={{ letterSpacing: '0.2em' }}>2019</Typography.Header2>
                <Typography.Header3 size='14px' style={{ marginTop: '0px' }}>INTO SEEECA</Typography.Header3>
                <Typography.Body size='9.6px'>(South-Eastern, Eastern Europe, and Central Asia)</Typography.Body>
                <Typography.Header size='32px' style={{ marginBottom: '-2px' }}>$58,078,885,502</Typography.Header>
              </RemittanceText>
              <RemittanceText bottom='5px'>
                <Typography.Header3 size='14px'>FROM SEEECA</Typography.Header3>
                <Typography.Body size='9.6px'>(South-Eastern, Eastern Europe, and Central Asia)</Typography.Body>
                <Typography.Header size='32px' style={{ marginBottom: '10px' }}>$30,142,138,156</Typography.Header>
              </RemittanceText>
            </Box>
          </BoxRow1>
          <BoxRow2>
            <Box color={Colors.DKBLUE}>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid #5B92E5' }}>
                <Typography.Header3 color={Colors.WHITE} size='14px' style={{ marginTop: '50px' }}>WESTERN BALKANS*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='25px' color={Colors.WHITE} style={{ marginBottom: '-5px' }}>1 M</Typography.Header>
                  <Typography.Body color={Colors.WHITE} size='14px' style={{ marginLeft: '10px', marginTop: '10px' }}>(3.3% of SEEECA)</Typography.Body>
                </div>
              </BoxText>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid #5B92E5' }}>
                <Typography.Header3 color={Colors.WHITE} size='14px'>EASTERN EUROPE AND SOUTH CAUCASUS*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='25px' color={Colors.WHITE} style={{ marginBottom: '-5px' }}>24 M</Typography.Header>
                  <Typography.Body color={Colors.WHITE} size='14px' style={{ marginLeft: '10px', marginTop: '10px' }}>(78.7% of SEEECA)</Typography.Body>
                </div>
              </BoxText>
              <BoxText bottom='5px'>
                <Typography.Header3 color={Colors.WHITE} size='14px'>CENTRAL ASIA*</Typography.Header3>
                <div style={{ display: 'flex', marginBottom: '40px' }}>
                  <Typography.Header size='25px' color={Colors.WHITE}>6 M</Typography.Header>
                  <Typography.Body color={Colors.WHITE} size='14px' style={{ marginLeft: '10px', marginTop: '10px' }}>(18% of SEEECA)</Typography.Body>
                </div>
                <Typography.Source color={Colors.WHITE} size='12px'>data source: <b>UN DESA, 2019</b></Typography.Source>
              </BoxText>
            </Box>
            <Box color={Colors.DKBLUE}>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid #5B92E5' }}>
                <Typography.Header3 color={Colors.WHITE} size='14px' style={{ marginTop: '50px' }}>WESTERN BALKANS*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='25px' color={Colors.WHITE} style={{ marginBottom: '-5px' }}>14 M</Typography.Header>
                  <Typography.Body color={Colors.WHITE} size='14px' style={{ marginLeft: '10px', marginTop: '10px' }}>(3.5% of SEEECA)</Typography.Body>
                </div>
              </BoxText>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid #5B92E5' }}>
                <Typography.Header3 color={Colors.WHITE} size='14px'>EASTERN EUROPE AND SOUTH CAUCASUS*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='25px' color={Colors.WHITE} style={{ marginBottom: '-5px' }}>30 M</Typography.Header>
                  <Typography.Body color={Colors.WHITE} size='14px' style={{ marginLeft: '10px', marginTop: '10px' }}>(18.7% of SEEECA)</Typography.Body>
                </div>
              </BoxText>
              <BoxText bottom='5px'>
                <Typography.Header3 color={Colors.WHITE} size='14px'>CENTRAL ASIA*</Typography.Header3>
                <div style={{ display: 'flex', marginBottom: '40px' }}>
                  <Typography.Header size='25px' color={Colors.WHITE}>73 M</Typography.Header>
                  <Typography.Body color={Colors.WHITE} size='14px' style={{ marginLeft: '10px', marginTop: '10px' }}>(77.7% of SEEECA)</Typography.Body>
                </div>
                <Typography.Source color={Colors.WHITE} size='12px'>data source: <b>UN DESA, 2019</b></Typography.Source>
              </BoxText>
            </Box>
            <Box color='#8DCDC9'>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid white' }}>
                <Typography.Header3 color={Colors.DKBLUE} size='14px' style={{ marginTop: '15px' }}>WESTERN BALKANS*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='21px' color={Colors.DKBLUE} style={{ marginBottom: '-10px' }}>$6 B</Typography.Header>
                  <Typography.Body color={Colors.DKBLUE} size='12px' style={{ marginLeft: '10px', marginTop: '9px' }}>remitted into the region</Typography.Body>
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='21px' color={Colors.DKBLUE} style={{ marginBottom: '-5px' }}>$473 M</Typography.Header>
                  <Typography.Body color={Colors.DKBLUE} size='12px' style={{ marginLeft: '10px', marginTop: '9px' }}>remitted from the region</Typography.Body>
                </div>
              </BoxText>
              <BoxText bottom='16px' style={{ borderBottom: '1px solid white' }}>
                <Typography.Header3 color={Colors.DKBLUE} size='14px'>EASTERN EUROPE AND SOUTH CAUCASUS*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='21px' color={Colors.DKBLUE} style={{ marginBottom: '-5px' }}>$35 B</Typography.Header>
                  <Typography.Body color={Colors.DKBLUE} size='12px' style={{ marginLeft: '10px', marginTop: '9px' }}>remitted into the region</Typography.Body>
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='21px' color={Colors.DKBLUE} style={{ marginBottom: '-5px' }}>$26 B</Typography.Header>
                  <Typography.Body color={Colors.DKBLUE} size='12px' style={{ marginLeft: '10px', marginTop: '9px' }}>remitted from the region</Typography.Body>
                </div>
              </BoxText>
              <BoxText bottom='5px'>
                <Typography.Header3 color={Colors.DKBLUE} size='14px'>CENTRAL ASIA*</Typography.Header3>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='21px' color={Colors.DKBLUE} style={{ marginBottom: '-5px' }}>$14 B</Typography.Header>
                  <Typography.Body color={Colors.DKBLUE} size='12px' style={{ marginLeft: '10px', marginTop: '9px' }}>remitted into the region</Typography.Body>
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography.Header size='21px' color={Colors.DKBLUE}>$4 B</Typography.Header>
                  <Typography.Body color={Colors.DKBLUE} size='12px' style={{ marginLeft: '10px', marginTop: '9px' }}>remitted from the region</Typography.Body>
                </div>
                <Typography.Source color={Colors.DKBLUE} size='12px'>data source: <b>World Bank, 2019</b></Typography.Source>
              </BoxText>
            </Box>
          </BoxRow2>
        </div>
        <div width='100%' style={{ textAlign: 'center', marginBottom: '30px' }}>
          <Typography.Header2 size='16px' bottom='10px'>explore countries</Typography.Header2>
          <img src={Arrow} style={{ maxWidth: '100%', cursor: 'pointer' }} onClick={() => countriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}/>
        </div>
        <Typography.Header2 ref={countriesRef} size='25px' style={{ paddingTop: '30px' }}>Countries and Territories</Typography.Header2>
        <CountriesRow>
          {allCountries.map((value, index) =>
            <Button key={index} onClick={() => { window.location.href = toURL(value) }}>
              <Typography.Header3 bottom='0px' style={{ letterSpacing: '0.1em', marginTop: hasLineBreak(value) ? '14px' : '24px' }}>{value.toUpperCase()}</Typography.Header3>
            </Button>
          )}
          <KosovoButton>
            <Typography.Header3 bottom='0px' style={{ letterSpacing: '0.1em', marginTop: '14px', opacity: '0.5' }}>KOSOVO<br/>[UNDER SCR 1244]</Typography.Header3>
          </KosovoButton>
        </CountriesRow>
        <Typography.Body size='14px' style={{ marginBottom: '50px' }}>
          <b>*Western Balkans:</b> Albania, Bosnia and Herzegovina, Montenegro, North Macedonia, Serbia, Kosovo (UNSCR 1244);<br/>
          <b>*Eastern Europe and South Caucases:</b> Armenia, Azerbaijan, Belarus, Georgia, Republic of Moldova, Turkey, Russian Federation, Ukraine;<br/>
          <b>*Central Asia:</b> Kazakhstan, Kyrgyzstan, Tajikistan, Turkmenistan, Uzbekistan.
        </Typography.Body>
      </MetaContainer>
      <Footer />
    </>
  )
}

export default Home
