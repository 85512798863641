import styled from 'styled-components'
import { Colors } from '../../styles'

export const MetaContainer = styled.div`
  width: 88%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 6%;
  @media print {
    display: block;
  }
  
`

export const Title = styled.h1`
  color: ${({ color }) => color || Colors.DKBLUE};
  font-size: 65px; 
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  line-height: 76px;
  padding-bottom: 24px;
`

export const HeaderIcon = styled.img`
  padding-top: 15px;
  padding-bottom: 5px;
`
