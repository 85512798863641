import PropTypes from 'prop-types'
import { React, useEffect, useState } from 'react'
import EconomyHeader from '../../images/economy-header.svg'
import MigrationHeader from '../../images/migration-header.svg'
import PopulationHeader from '../../images/population-header.svg'
import { CapitalizeString } from '../../styles'
import Footer from '../Footer'
import Header from '../Header'
import HeadlineNums from '../headline_nums.json'
import Text from '../text.json'
import Economy from './Economy'
import Education from './Education'
import FastFacts from './FastFacts'
import ForcedMigration from './ForcedMigration'
import Migration from './Migration'
import Navbar from './Navbar'
import Population from './Population'
import { HeaderIcon, MetaContainer, Title } from './styles'

const Country = ({ match }) => {
  const slug = match.params.slug

  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState('')
  const [countryData, setCountryData] = useState({})

  const getCountryData = () => {
    const name = CapitalizeString(slug.replaceAll('-', ' '))
    setCountry(name)
    for (let i = 0; i < HeadlineNums.length; i++) {
      if (HeadlineNums[i].country === name) {
        setCountryData(HeadlineNums[i])
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    getCountryData()
  }, [])

  if (loading) {
    return <div/>
  }

  return (
    <>
      <Header country={country} />
      <div style={{ background: '#F2F5FA' }}>
        <MetaContainer>
          <Title>{country}</Title>
          <FastFacts
            text={Text.country.fastFacts}
            population={countryData.population}
            immigrants={countryData.immigrants}
            emigrants={countryData.emigrants}
            inflows={countryData.inflows}
            outflows={countryData.outflows}
          />
        </MetaContainer>
      </div>
      <Navbar
        slug={slug}
        country={country}
        educationPct={countryData.education}
        unemployment = {countryData.unemployment}
        trafficking={countryData.trafficking}
        idps={countryData.idps}
        returnees={countryData.returnees}
        pop={countryData.population}
        immigrants={countryData.immigrants}
        emigrants={countryData.emigrants}
        inflows={countryData.inflows}
        outflows={countryData.outflows} />
      <MetaContainer>
        <div id="migration" className="section">
          <HeaderIcon src={MigrationHeader}/>
        </div>
        <Migration
          country={country}
          countryURL={slug}
          emigrants={countryData.emigrants}
          immigrants={countryData.immigrants}
          text = {Text.country.migration}
        />
        <ForcedMigration
          country={country}
          trafficking={countryData.trafficking}
          idps={countryData.idps}
          returnees={countryData.returnees}
          text = {Text.country.forcedMigration}
        />
        <div id="population" className="section">
          <HeaderIcon src={PopulationHeader}/>
        </div>
        <Population
          country = {country}
          countryURL = {slug}
          population = {countryData.population.value}
          text = {Text.country.population}
        />
        <Education
          country={country}
          countryURL={slug}
          educationPct={countryData.education}
          educationText = {Text.country.education}
          dependencyText = {Text.country.dependency}
        />
        <div id="economy" className="section">
          <HeaderIcon src={EconomyHeader}/>
        </div>
        <Economy
          country = {country}
          countryURL = {slug}
          unemployment = {countryData.unemployment}
          text = {Text.country.economy}
        />
      </MetaContainer>
      <Footer />
    </>
  )
}
Country.propTypes = {
  match: PropTypes.object
}
export default Country
