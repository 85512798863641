import PropTypes from 'prop-types'
import { React } from 'react'
import { Link } from 'react-router-dom'
import BlueLogo from '../../images/iom-logo-blue.svg'
import { Colors, Typography } from '../../styles'
import { Box, Col, Dropdown, Row } from './styles'

const Header = ({ country }) => {
  const allCountries = ['Region', 'Albania', 'Azerbaijan', 'Armenia', 'Belarus', 'Bosnia and Herzegovina', 'Georgia',
    'Kazakhstan', 'Kyrgyzstan', 'Montenegro', 'North Macedonia', 'Republic of Moldova', 'Russian Federation', 'Serbia',
    'Tajikistan', 'Turkey', 'Turkmenistan', 'Ukraine', 'Uzbekistan']
  const renderOption = ({ option }) => <option value={option}>{option}</option>

  return (
    <div width="100%" style={{ zIndex: 999, position: 'relative' }}>
      <Row>
        <Col width='15%'>
          <Link to="/">
            <img src={BlueLogo} style={{ maxWidth: '100%', display: 'block' }}/>
          </Link>
        </Col>
        <Col width='52%'>
          <Typography.Header color={Colors.DKBLUE}>Migration Data Platform for Evidence-Based Regional Development (M-POWERD)</Typography.Header>
          <Typography.Header3 size='14px' style={{ marginTop: '-20px', marginBottom: '10px' }}>IN SOUTH-EASTERN, EASTERN EUROPE AND CENTRAL ASIA (SEEECA)</Typography.Header3>
        </Col>
        <Box>
          <Typography.Header3 size='12px' color={Colors.DKBLUE} style={{ marginTop: '0px', marginBottom: '10px' }}>SELECT COUNTRY</Typography.Header3>
          <Dropdown
            value = {country}
            onChange={event => {
              const value = event.target.value
              let url = '/'
              if (value !== '' && value !== 'Region') {
                url = '/country/' + value.replaceAll(' ', '-')
              }
              window.location.href = url.toLowerCase()
            }}
          >
            {allCountries.map((value) => renderOption({ option: value }))}
          </Dropdown>
        </Box>
      </Row>
    </div>
  )
}
Header.propTypes = {
  country: PropTypes.string
}
export default Header
