import styled from 'styled-components'
import { Colors } from '../../styles'

export const MetaContainer = styled.div`
  width: 88%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 6%;
`

export const Button = styled.div`
  width: 205px;
  height: 70px;
  background: #F2F5FA;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  transition: all 0.1s ease;
  &:hover {
    background-color: ${Colors.LTBLUE};
  }
`

export const KosovoButton = styled.div`
  width: 205px;
  height: 70px;
  background: #E4E4E4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: default;
  margin-top: 20px;
  text-align: center;
  padding-top: 0px;
  padding-left: 35px;
  padding-right: 35px;
  transition: all 0.1s ease;
`

export const CountriesRow = styled.div`
  display: grid;
  grid-row-gap: 0px;
  justify-items: center;
  margin-bottom: 100px;
  @media (min-width: 1301px) {
    grid-template-columns: repeat(4, [col-start] minmax(205px, 1fr) [col-end]);
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, [col-start] minmax(205px, 1fr) [col-end]);
  }
  @media (max-width: 975px) {
    grid-template-columns: repeat(2, [col-start] minmax(205px, 1fr) [col-end]);
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, [col-start] minmax(205px, 1fr) [col-end]);
  }
`

export const BoxRow1 = styled.div`
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  margin-bottom: 10px;
  @media (min-width: 1301px) {
    grid-template-columns: repeat(3, [col-start] minmax(340px, 1fr) [col-end]);
    margin-top: -575px;
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, [col-start] minmax(340px, 1fr) [col-end]);
    margin-top: -400px;
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, [col-start] minmax(340px, 1fr) [col-end]);
    margin-top: 0px;
  }
`

export const BoxRow2 = styled.div`
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  margin-bottom: 50px;
  @media (min-width: 1301px) {
    grid-template-columns: repeat(3, [col-start] minmax(340px, 1fr) [col-end]);
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, [col-start] minmax(340px, 1fr) [col-end]);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, [col-start] minmax(340px, 1fr) [col-end]);
  }
`

export const Box = styled.div`
  width: 340px;
  // height: 100%;
  background: ${(props) => props.color};
  opacity: 0.8;
  border-radius: 8px;
`

export const BoxText = styled.div`
  margin: 0px 41px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: ${(props) => props.bottom};
  text-align: left;
`

export const RemittanceText = styled.div`
  margin: 0px 36px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: ${(props) => props.bottom};
  text-align: left;
`
