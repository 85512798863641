import PropTypes from 'prop-types'
import React from 'react'
import { Colors, FormatJSONText, SDG, Typography } from '../../../styles'
import { Box, Graph, Row, SmallBox, SmallBoxContainer } from './styles'

const Economy = ({ country, countryURL, unemployment, text }) => {
  const gdp = '../html-graphs/' + countryURL + '-gdp.html'
  const gdpPrint = '../svg-graphs/' + countryURL + '-gdp.svg'

  const gdpPerCapita = '../html-graphs/' + countryURL + '-gdp-per-capita.html'
  const gdpPerCapitaPrint = '../svg-graphs/' + countryURL + '-gdp-per-capita.svg'

  const remittances = '../html-graphs/' + countryURL + '-remittances.html'
  const remittancesPrint = '../svg-graphs/' + countryURL + '-remittances.svg'

  const treeMap = '../svg-graphs/' + countryURL + '-tree-map.svg'
  const Migrants = '../svg-graphs/' + countryURL + '-migrants.svg'
  const Sectors = '../svg-graphs/' + countryURL + '-sectors.svg'
  return (
    <div>
      <Typography.Header style={{ marginTop: '0px' }}>{FormatJSONText(text.title, country)}</Typography.Header>
      <Typography.TextContainer bottom='30px'>
        <Typography.Body>{text.description}</Typography.Body>
      </Typography.TextContainer>
      <Row bottom='80px'>
        <div>
          <Graph>
            <iframe cursor="default" width="100%" height="100%" src={gdp}/>
          </Graph>
          <img src={gdpPrint} width='70%' className="print-img"/>
          <div style={{ textAlign: 'center', width: '90%', marginTop: '-25px' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.gdpGraph.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.gdpGraph.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 19</Typography.Body>
            </SDG.Button>
          </div>
        </div>
        <div>
          <Graph>
            <iframe cursor="default" width="100%" height="100%" src={gdpPerCapita}/>
          </Graph>
          <img src={gdpPerCapitaPrint} width='70%' className="print-img" />
          <div style={{ textAlign: 'center', width: '90%', marginTop: '-25px' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.gdpPerCapitaGraph.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.gdpPerCapitaGraph.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 19</Typography.Body>
            </SDG.Button>
          </div>
        </div>
      </Row>
      <Row bottom='80px'>
        <div>
          <Graph>
            <iframe cursor="default" width="100%" height="100%" src={remittances}/>
          </Graph>
          <img src={remittancesPrint} width='70%' className="print-img"/>
          <div style={{ textAlign: 'center', width: '90%', marginTop: '-25px' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.remittanceGraph.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.remittanceGraph.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 20</Typography.Body>
            </SDG.Button>
          </div>
        </div>
        <div>
          <div style={{ marginLeft: '30px' }}>
            <img src={treeMap} width='85%'/>
          </div>
          <div style={{ textAlign: 'center', width: '90%', marginTop: '10px' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.treeMap.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.treeMap.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 20</Typography.Body>
            </SDG.Button>
          </div>
        </div>
      </Row>
      <Row bottom='80px'>
        <div>
          <img src={Migrants} width="90%"/>
          <div style={{ textAlign: 'center', marginTop: '10px', width: '90%' }}>
            <Typography.Header3 size='18px' style={{ marginBottom: '-10px' }}>{text.workingAgeGraph.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.workingAgeGraph.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 19</Typography.Body>
            </SDG.Button>
          </div>
        </div>
        <div>
          <Box>
            <Typography.Header2 size='24px' bottom='27px'>{text.didYouKnow.title}</Typography.Header2>
            <Typography.Body size='18px' style={{ lineHeight: '21px' }}>{text.didYouKnow.label}</Typography.Body>
          </Box>
        </div>
      </Row>
      <Row bottom='100px'>
        <div>
          <img src={Sectors} width="90%"/>
          <div style={{ textAlign: 'center', marginTop: '10px', width: '90%' }}>
            <Typography.Header3 size='18px' style={{ marginBottom: '-10px' }}>{text.sectorGraph.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.sectorGraph.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 19</Typography.Body>
            </SDG.Button>
          </div>
        </div>
        <SmallBoxContainer>
          <SmallBox bottom='10px'>
            <Typography.Header3 size='14px' color={Colors.DKBLUE} style={{ marginBottom: '5px' }}>{FormatJSONText(text.unemployment.country, country).toUpperCase()}</Typography.Header3>
            <Typography.Header color={Colors.DKBLUE} style={{ marginBottom: '0px' }}>{unemployment}%</Typography.Header>
          </SmallBox>
          <SmallBox bottom='10px'>
            <Typography.Header3 size='14px' style={{ marginBottom: '5px' }}>{text.unemployment.seeeca}</Typography.Header3>
            <Typography.Header style={{ marginBottom: '0px' }}>10.1%</Typography.Header>
          </SmallBox>
          <SmallBox bottom='10px'>
            <Typography.Header3 size='14px' style={{ marginBottom: '5px' }}>{text.unemployment.world}</Typography.Header3>
            <Typography.Header style={{ marginBottom: '0px' }}>6.5%</Typography.Header>
          </SmallBox>
          <div style={{ textAlign: 'center' }} id="econ-source">
            <Typography.Header3 size='18px' style={{ marginBottom: '-10px' }}>{text.unemployment.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.unemployment.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 19</Typography.Body>
            </SDG.Button>
          </div>
        </SmallBoxContainer>
      </Row>

    </div>
  )
}

Economy.propTypes = {
  country: PropTypes.string,
  countryURL: PropTypes.string,
  unemployment: PropTypes.number,
  text: PropTypes.string
}

export default Economy
