import styled from 'styled-components'
import { Colors } from '../../styles'

export const Row = styled.div`
  display: flex;
  padding-top: 50px;
  padding-left: 6%;
  padding-bottom: 50px;
  background: ${Colors.DKBLUE};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  flex-direction: column;
  width: ${(props) => props.width};
  align-items: center;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%
  }
`
