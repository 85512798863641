import * as Colors from './Colors'
import * as SDG from './SDG'
import * as Typography from './Typography'

const FormatJSONText = (text, country) => {
  const countryApos = country.charAt(country.length - 1) === 's' ? country + '\'' : country + '\'s'
  return text.replaceAll('XXXXX\'S', countryApos).replaceAll('XXXXX', country)
}

const CapitalizeString = (str) => {
  const numWords = str.split(' ').length
  if (numWords === 1) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } else {
    const words = str.split(' ')
    words[0] = CapitalizeString(words[0])
    words[words.length - 1] = CapitalizeString(words[words.length - 1])
    return words.join(' ')
  }
}

export { Colors, Typography, SDG, FormatJSONText, CapitalizeString }
