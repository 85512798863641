import styled from 'styled-components'
import { Colors } from '../../styles'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 18px;
  padding-left: 4%;
  background: #F2F5FA;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  flex-direction: column;
  width: ${(props) => props.width};
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%
  }
`

export const Box = styled.div`
  height: 55px;
  width: 24%;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 16px;
  background: #CCD6EC;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-top: 0px;
    margin-bottom: 16px
  }
`

export const Dropdown = styled.select`
  width: 98%;
  height: 25px;
  border: none;
  outline: none;
  border-bottom: 1px solid ${Colors.DKBLUE};
  font-size: 14px;
  font-family: Work Sans;
  font-weight: bold;
  color: ${Colors.DKBLUE};
  background: #CCD6EC;
`
