import styled from 'styled-components'

export const Row = styled.div`
  display: grid;
  grid-row-gap: 20px;
  justify-items: space-between;
  margin-bottom: ${(props) => props.bottom};
  @media (min-width: 1176px) {
    grid-template-columns: repeat(2, [col-start] minmax(350px, 1fr) [col-end]);
  }
  @media (max-width: 1175px) {
    grid-template-columns: repeat(1, [col-start] minmax(350px, 1fr) [col-end]);
  }
`

export const Graph = styled.div`
  width: 95%;
  height: 420px;
  @media print {
    display: none;
  }
`
