export const DKBLUE = '#0033A0'
export const LTBLUE = '#5B92E5'
export const YELLOW = '#FFB81C'
export const TEAL = '#5CB8B2'
export const ORANGE = '#FF671F'
export const RED = '#D22630'
export const GRAY = '#848181'
export const BLACK = '#000000'
export const WHITE = '#FFFFFF'
export const PINK = '#D81E80'
