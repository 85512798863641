import PropTypes from 'prop-types'
import React from 'react'
import { Colors, SDG, Typography } from '../../../styles'
import { Button, Col, FlexCol, Row } from './styles'

const FastFacts = ({ text, population, immigrants, emigrants, inflows, outflows }) => {
  const trend = (value, startYear) => {
    if (value === 'going down') {
      return (
        <>
          <Button color={Colors.DKBLUE} style={{ marginLeft: '25px' }}>
            <Typography.Body size='14px' color={Colors.WHITE}>going down</Typography.Body>
          </Button>
          <Typography.Body size='12px' color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>from {startYear}</Typography.Body>
        </>
      )
    } else {
      return (
        <>
          <Button color={Colors.LTBLUE} style={{ marginLeft: '25px' }}>
            <Typography.Body size='14px' color={Colors.WHITE}>going up</Typography.Body>
          </Button>
          <Typography.Body size='12px' color={Colors.LTBLUE} style={{ marginLeft: '5px' }}>from {startYear}</Typography.Body>
        </>
      )
    }
  }
  return (
    <div>
      <Typography.Header3 size='16px' style={{ marginBottom: '10px' }}>{text.title.toUpperCase()}</Typography.Header3>
      <Row style={{ paddingTop: '5px' }} bottom={true}>
        <Col size={3}>
          <FlexCol bottom="5px">
            <Typography.Body size='18px'>{text.population}<b>{population.value}</b></Typography.Body>
            {trend(population.trend, population.startYear) }
          </FlexCol>
        </Col>
        <Col size={3}>
          <FlexCol bottom="5px">
            <Typography.Body size='18px'>{text.immigrants}<b>{immigrants.value}</b></Typography.Body>
            { trend(immigrants.trend, immigrants.startYear) }
          </FlexCol>
          <FlexCol bottom="10px">
            <Typography.Body size='18px'>{text.emigrants}<b>{emigrants.value}</b></Typography.Body>
            { trend(emigrants.trend, emigrants.startYear) }
          </FlexCol>
        </Col>
      </Row>
      <Row top={true} style={{ paddingTop: '5px', marginBottom: '15px' }}>
        <FlexCol>
          <Typography.Source size='12px' bottom='5px'>data source: <b>{text.source1}</b></Typography.Source>
          <SDG.Button color={Colors.PINK} style={{ marginLeft: '20px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>SDG 10.7</Typography.Body>
          </SDG.Button>
          <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
          </SDG.Button>
        </FlexCol>
      </Row>
      <Row style={{ borderTop: 'none', borderBottom: 'none' }}>
        <Col size={1}>
          <FlexCol bottom="5px">
            <Typography.Body size='18px' >{text.inflows}<b>${inflows.value}</b></Typography.Body>
            { trend(inflows.trend, inflows.startYear) }
          </FlexCol>
        </Col>
        <Col size={1}>
          <FlexCol bottom='10px'>
            {outflows.value[0] != null &&
              <Typography.Body size='18px'>{text.outflows}<b>${outflows.value}</b></Typography.Body>
            }
            { outflows.value[0] != null && trend(outflows.trend, outflows.startYear) }
          </FlexCol>
        </Col>
      </Row>
      <Row style={{ borderTop: 'none', paddingTop: '5px', marginBottom: '15px' }}>
        <FlexCol>
          <Typography.Source size='12px' bottom='5px'>data source: <b>{text.source2}</b></Typography.Source>
          <SDG.Button color={Colors.PINK} style={{ marginLeft: '20px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>SDG 17.3</Typography.Body>
          </SDG.Button>
          <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
          </SDG.Button>
          <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>GCM 20</Typography.Body>
          </SDG.Button>
        </FlexCol>
      </Row>
    </div>
  )
}

FastFacts.propTypes = {
  text: PropTypes.object,
  population: PropTypes.object,
  immigrants: PropTypes.object,
  emigrants: PropTypes.object,
  inflows: PropTypes.object,
  outflows: PropTypes.object
}

export default FastFacts
