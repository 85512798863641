import PropTypes from 'prop-types'
import React from 'react'
import { Colors, FormatJSONText, SDG, Typography } from '../../../styles'
import { Box, Caption, Col, Graph, ResponsiveChart, Row, SectionTitle } from './styles.js'

const Education = ({ country, countryURL, educationPct, educationText, dependencyText }) => {
  const graphPath = '../svg-graphs/' + countryURL + '-dependency.svg'
  return (
    <div className="section">
      <Row bottom='70px'>
        <Col size={1}>
          <Typography.Header2 style={{ marginBottom: '10px' }}>{educationText.title}</Typography.Header2>
          <SDG.Button color={Colors.PINK} style={{ marginBottom: '10px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>SDG 4</Typography.Body>
          </SDG.Button>
          <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
          </SDG.Button>
        </Col>
        <Col size={4}>
          <Typography.Body>{FormatJSONText(educationText.description, country)}</Typography.Body>
          <br/>
          <SectionTitle>
            <Typography.Header3 size='15px' style={{ marginBottom: '5px' }}>{educationText.box.title}</Typography.Header3>
          </SectionTitle>
          <Box>
            <Row style={{ marginTop: '10px' }}>
              <Col size={2}>
                <Typography.Header style={{ marginTop: '14px' }}>{educationPct}%</Typography.Header>
              </Col>
              <Col size={3}>
                <Typography.Body top="15px">{educationText.box.label}</Typography.Body>
              </Col>
            </Row>
            <Typography.Source style={{ marginTop: '5px', marginBottom: '5px' }}>data source: <b>{educationText.box.source}</b></Typography.Source>
          </Box>
        </Col>
      </Row>
      <Row bottom='50px'>
        <Col size={1}>
          <Typography.Header2 style={{ marginBottom: '10px' }}>{dependencyText.title}</Typography.Header2>
          <SDG.Button color={Colors.PINK} style={{ marginBottom: '10px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>SDG 8</Typography.Body>
          </SDG.Button>
          <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
            <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
          </SDG.Button>
        </Col>
        <Col size={4}>
          <Typography.Body>{FormatJSONText(dependencyText.description, country)}</Typography.Body>
        </Col>
      </Row>
      <ResponsiveChart>
        <Graph src={graphPath}/>
      </ResponsiveChart>
      <Caption >
        <Typography.Header3 style={{ marginBottom: '-10px' }}>{dependencyText.graph.title}</Typography.Header3>
        <Typography.Source>data source: <b>{dependencyText.graph.source}</b></Typography.Source>
      </Caption>
    </div>
  )
}

Education.propTypes = {
  country: PropTypes.string,
  countryURL: PropTypes.string,
  educationPct: PropTypes.number,
  educationText: PropTypes.object,
  dependencyText: PropTypes.object
}

export default Education
