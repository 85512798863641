import PropTypes from 'prop-types'
import React from 'react'
import { Colors, FormatJSONText, SDG, Typography } from '../../../styles'
import { Box, BoxBelow, Container, Row } from './styles.js'

const ForcedMigration = ({ country, trafficking, idps, returnees, text }) => {
  return (
    <Row bottom='50px'>
      <div style={{ flex: 1 }}>
        <Box>
          <Typography.Header3 size='14px' style={{ marginBottom: '20px' }}>{text.trafficking.title}</Typography.Header3>
          <Typography.Header>{trafficking} victims</Typography.Header>
          <Typography.Body>{FormatJSONText(text.trafficking.description, country)}</Typography.Body>
          <Typography.Source size='12px' style={{ marginTop: '20px', marginBottom: '0px' }}>data source: <b>{text.trafficking.source}</b></Typography.Source>
        </Box>
        <BoxBelow>
          <div>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 10</Typography.Body>
            </SDG.Button>
          </div>
        </BoxBelow>
      </div>
      <div style={{ flex: 1 }}>
        <Box>
          <Typography.Header3 size='14px' style={{ marginBottom: '20px' }}>{text.displaced.title}</Typography.Header3>
          <Typography.Header>{idps}</Typography.Header>
          <Container>
            <Typography.Body>{FormatJSONText(text.displaced.description, country)}</Typography.Body>
          </Container>
          <Typography.Source size='12px' style={{ marginTop: '20px', marginBottom: '0px' }}>data source: <b>{text.displaced.source}</b></Typography.Source>
        </Box>
        <BoxBelow>
          <div>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 2</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 7</Typography.Body>
            </SDG.Button>
          </div>
        </BoxBelow>
      </div>
      <div style={{ flex: 1 }}>
        <Box>
          <Typography.Header3 size='14px' style={{ marginBottom: '20px' }}>{text.returnees.title}</Typography.Header3>
          <Typography.Header>{returnees}</Typography.Header>
          <Container>
            <Typography.Body>{FormatJSONText(text.returnees.description, country)}</Typography.Body>
          </Container>
          <Typography.Source size='12px' style={{ marginTop: '20px', marginBottom: '0px' }}>data source: <b>{text.returnees.source}</b></Typography.Source>
        </Box>
        <BoxBelow>
          <div>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
            <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 21</Typography.Body>
            </SDG.Button>
          </div>
        </BoxBelow>
      </div>
    </Row>
  )
}

ForcedMigration.propTypes = {
  country: PropTypes.string,
  trafficking: PropTypes.string,
  idps: PropTypes.string,
  returnees: PropTypes.string,
  text: PropTypes.object
}

export default ForcedMigration
