import PropTypes from 'prop-types'
import React from 'react'
import { Colors, FormatJSONText, SDG, Typography } from '../../../styles'
import { Graph, Row } from './styles'

const Population = ({ country, countryURL, population, text }) => {
  const populationGraph = '../html-graphs/' + countryURL + '-population.html'
  const populationPrintGraph = '../svg-graphs/' + countryURL + '-population.svg'

  const fertilityGraph = '../html-graphs/' + countryURL + '-fertility.html'
  const fertilityPrintGraph = '../svg-graphs/' + countryURL + '-fertility.svg'
  return (
    <div>
      <Typography.Header style={{ marginTop: '0px' }}>{FormatJSONText(text.title, country)}</Typography.Header>
      <Typography.TextContainer bottom='30px'>
        <Typography.Body>{FormatJSONText(text.description, country)}</Typography.Body>
      </Typography.TextContainer>
      <Row bottom='60px'>
        <div>
          <Typography.Body size='18px'>{text.populationGraph.label}<b>{population}</b></Typography.Body>
          <Graph>
            <iframe cursor="default" width="100%" height="100%" src={populationGraph}/>
          </Graph>
          <img src={populationPrintGraph} width='60%' className="print-img"/>
          <div style={{ textAlign: 'center', width: '90%', marginTop: '-25px' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.populationGraph.title}</Typography.Header3>
            <Typography.Source style={{ marginBottom: '7px' }}>data source: <b>{text.populationGraph.source}</b></Typography.Source>
            <SDG.Button color={Colors.DKBLUE}>
              <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
            </SDG.Button>
          </div>
        </div>
        <div>
          <Typography.Body size='18px'><b>{text.fertilityGraph.labelBold}</b>{text.fertilityGraph.label}</Typography.Body>
          <Graph>
            <iframe cursor="default" width="100%" height="100%" src={fertilityGraph}/>
          </Graph>
          <img src={fertilityPrintGraph} width='60%' className="print-img"/>
          <div style={{ marginTop: '-25px', textAlign: 'center' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.fertilityGraph.title}</Typography.Header3>
            <Typography.Source>data source: <b>{text.fertilityGraph.source}</b></Typography.Source>
            <div style={{ marginTop: '-7px' }}>
              <SDG.Button color={Colors.PINK}>
                <Typography.Body color={Colors.WHITE} size='14px'>SDG 3.7</Typography.Body>
              </SDG.Button>
              <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
                <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
              </SDG.Button>
            </div>
          </div>
        </div>
      </Row>
    </div>
  )
}

Population.propTypes = {
  country: PropTypes.string,
  countryURL: PropTypes.string,
  population: PropTypes.string,
  text: PropTypes.object
}

export default Population
