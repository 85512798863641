import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => props.bottom};
  width: 100%;
  @media (max-width: 850px) {
    flex-direction: column;
    margin-bottom: 20px
  }
  @media print {
    flex-direction: row;
  }
`

export const Box = styled.div`
  height: 85%;
  width: 82%;
  padding-top: 20px;
  background: #D8E0FD;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  @media (max-width: 850px) {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  @media print {
    width: 90%;
  }
`
export const BoxBelow = styled.div`
  width: 82%;
  background: transparent;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 50px;
  }
  @media print {
    width: 90%;
  }
`

export const Container = styled.div`
  width: 85%;
  margin-left: 7%;
`
