import styled from 'styled-components'

export const Row = styled.div`
  display: grid;
  grid-row-gap: 20px;
  justify-items: space-between;
  margin-bottom: ${(props) => props.bottom};
  @media (min-width: ${(props) => props.splitWidth + 1}px) {
    grid-template-columns: repeat(2, [col-start] minmax(350px, 1fr) [col-end]);
  }
  @media (max-width: ${(props) => props.splitWidth}px) {
    grid-template-columns: repeat(1, [col-start] minmax(350px, 1fr) [col-end]);
  }
`

export const Graph = styled.div`
  width: 95%;
  height: 86%;
  @media (max-width: 1175px) {
    height: 420px;
  }
  @media print {
    display: none
  }
`

export const Map = styled.div`
  width: 95%;
  height: 550px;
`

export const Caption = styled.div`
  margin-top: 20px;
  margin-bottom: 65px;
`
