
import styled from 'styled-components'
import { Colors } from '../../../styles'

export const NavBar = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  background: ${Colors.DKBLUE};
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    text-align: center;
    display: none;
  }
`

export const NavRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 91px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-left: 0
    
  }
`

export const NavCol = styled.div`
  flex-direction: column;
  padding-right: 22px;
  align-items: center;
  border-right: 1px solid ${Colors.WHITE};
  @media only screen and (max-width: 768px) {
    padding-right: 0 !important;
    border-right: 0;
  }
`

export const Icon = styled.div`
  flex-direction: column;
  padding-left: 22px;
  padding-right: 12px;
  padding-top: 3px;
  @media only screen and (max-width: 768px) {
    padding: 0
  }
`

export const TextLink = styled.a`
  color: white;
  text-decoration: none;
`
