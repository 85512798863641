import PropTypes from 'prop-types'
import { React, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import DownloadIcon from '../../../images/download-icon.svg'
import EconomyIcon from '../../../images/economy-icon.svg'
import MigrationIcon from '../../../images/migration-icon.svg'
import PopulationIcon from '../../../images/population-icon.svg'
import { Colors, Typography } from '../../../styles'
import { Icon, NavBar, NavCol, NavRow, TextLink } from './styles'

let navbar
let navbarOffset

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const Navbar = ({ slug, country, educationPct, unemployment, trafficking, idps, returnees, pop, immigrants, emigrants, inflows, outflows }) => {
  const migration = '/country/' + slug + '#migration'
  const population = '/country/' + slug + '#population'
  const economy = '/country/' + slug + '#economy'

  useEffect(() => {
    navbar = document.getElementById('navbar')
    navbarOffset = navbar.offsetTop
  }, [])
  return (

    <NavBar id="navbar">
      <NavRow>
        <NavCol style={{ borderRight: 'none', paddingRight: '15px' }}>
          <Typography.Body size='14px' color={Colors.WHITE}>sections:</Typography.Body>
        </NavCol>
        <Icon>
          <HashLink to={migration}>
            <img src={MigrationIcon}/>
          </HashLink>
        </Icon>
        <NavCol>
          <HashLink to={migration} style={{ textDecoration: 'none' }}>
            <Typography.Body size='14px' color={Colors.WHITE} style={{ letterSpacing: '0.1em' }}>MIGRATION</Typography.Body>
          </HashLink>
        </NavCol>
        <Icon>
          <HashLink to={population}>
            <img src={PopulationIcon}/>
          </HashLink>
        </Icon>
        <NavCol>
          <HashLink to={population} style={{ textDecoration: 'none' }}>
            <Typography.Body size='14px' color={Colors.WHITE} style={{ letterSpacing: '0.1em' }}>POPULATION</Typography.Body>
          </HashLink>
        </NavCol>
        <Icon>
          <HashLink to={economy}>
            <img src={EconomyIcon}/>
          </HashLink>
        </Icon>
        <NavCol>
          <HashLink to={economy} style={{ textDecoration: 'none' }}>
            <Typography.Body size='14px' color={Colors.WHITE} style={{ letterSpacing: '0.1em' }}>ECONOMY</Typography.Body>
          </HashLink>
        </NavCol>
        <Icon>
          <a onClick={() => openInNewTab(`../reports/${slug}-report.pdf`)}>
            <img src={DownloadIcon}/>
          </a>
        </Icon>
        <NavCol style={{ borderRight: 'none', cursor: 'pointer' }}>
          <TextLink onClick={() => openInNewTab(`../reports/${slug}-report.pdf`)}>
            DOWNLOAD PDF
          </TextLink>
        </NavCol>
      </NavRow>
    </NavBar>

  )
}
Navbar.propTypes = {
  slug: PropTypes.string,
  country: PropTypes.string,
  educationPct: PropTypes.number,
  unemployment: PropTypes.number,
  trafficking: PropTypes.string,
  idps: PropTypes.string,
  returnees: PropTypes.string,
  pop: PropTypes.object,
  immigrants: PropTypes.object,
  emigrants: PropTypes.object,
  inflows: PropTypes.object,
  outflows: PropTypes.object
}
export default Navbar

window.onscroll = setSticky
window.onbeforeprint = () => navbar != null ? navbar.classList.remove('sticky') : null
window.onafterprint = () => navbar != null && window.pageYOffset >= navbarOffset ? navbar.classList.add('sticky') : null

function setSticky () {
  if (navbar != null) {
    if (window.pageYOffset >= navbarOffset) {
      navbar.classList.add('sticky')
    } else {
      navbar.classList.remove('sticky')
    }
  }
}
