import styled from 'styled-components'

export const Row = styled.div`
  display: grid;
  grid-row-gap: 20px;
  justify-items: space-between;
  margin-bottom: ${(props) => props.bottom};
  @media (min-width: 1176px) {
    grid-template-columns: repeat(2, [col-start] minmax(350px, 1fr) [col-end]);
  }
  @media (max-width: 1175px) {
    grid-template-columns: repeat(1, [col-start] minmax(350px, 1fr) [col-end]);
  }
`

export const Box = styled.div`
  background: #EFF4FC;
  padding: 50px 50px 60px 50px;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 100px;
  width: 55%;
`

export const Graph = styled.div`
  width: 90%;
  height: 382px;
  @media print {
    display: none;
  }
`

export const SmallBox = styled.div`
  background: #EFF4FC;
  padding: 15px 60px 11px 60px;
  border-radius: 8x;
  text-align: center;
  justify-content: center;
  margin-bottom: ${(props) => props.bottom};
  display: grid;
  grid-template-rows: 1fr 1fr;
`

export const SmallBoxContainer = styled.div`
  padding-left: 25%;
  width: 350px;
  margin-top: 15px;
  @media (max-width: 1175px) {
    width: 100%;
    padding-left: 0;
  }
  @media print {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
`
