import styled from 'styled-components'
import * as Colors from './Colors'

export const Header = styled.h1`
    color: ${({ color }) => color || Colors.BLACK};
    font-size: ${({ size }) => size || '35px'}; 
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 1em;
    padding-top: ${({ top }) => top || '5px'};
    padding-bottom: ${({ bottom }) => bottom || '5px'};
    margin-top: 0;
    @media only screen and (max-width: 768px) {
        font-size: ${({ size }) => size * 0.8 || '30px'}; 
    }
`

export const Header2 = styled.h2`
    color: ${({ color }) => color || Colors.BLACK};
    font-size: ${({ size }) => size || '22px'}; 
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
`

export const Header3 = styled.h3`
    color: ${({ color }) => color || Colors.BLACK};
    font-size: ${({ size }) => size || '18px'}; 
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2em;
    margin: 0;
`

export const Body = styled.p`
    color: ${({ color }) => color || Colors.BLACK};
    font-size: ${({ size }) => size || '16px'};
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    margin-top: 3px;
    margin-bottom: 3px;
    line-height: 1.2em;
    padding-bottom: ${({ bottom }) => bottom || '0px'};
    padding-top: ${({ top }) => top || '0px'};
    @media only screen and (max-width: 768px) {
        padding-top: 0;
    }
`

export const Source = styled.p`
    color: ${({ color }) => color || Colors.BLACK};
    font-size: ${({ size }) => size || '14px'};
    font-family: Work Sans;
    font-style: normal;
`

export const TextContainer = styled.div`
    width: 80%;
    text-align: left;
    padding-bottom: ${({ bottom }) => bottom || '0px'};
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`
