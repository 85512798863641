import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  border-top: ${(props) => props.top ? 'none' : '1px solid'};
  border-bottom: ${(props) => props.bottom ? 'none' : '1px solid'};
  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  flex: ${(props) => props.size};
  flex-wrap: wrap;
`

export const FlexCol = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ bottom }) => bottom || '0px'};
`

export const Button = styled.div`
  display: inline-block;
  padding: 0px 8px;
  background: ${(props) => props.color};
  border-radius: 8px;
  
`
