import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => props.bottom};
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  flex: ${(props) => props.size};
`

export const Box = styled.div`
  width: 35%;
  padding: 10px 20px 10px 25px;
  background: #D8E0FD;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    width: 90%;
    text-align: center;
  }
  @media print {
    width: 60%;
  }
`
export const SectionTitle = styled.div`
  width: 35%;
  text-align: center;
  @media only screen and (max-width: 768px) {
    width: 100%
  }
`

export const Graph = styled.img`
  width: 100%;
  @media only screen and (max-width: 768px) {
    height: 300px;
    width: auto;
  }
`
export const ResponsiveChart = styled.div`
   @media only screen and (max-width: 768px) {
    height: 300px;
    overflow-x: scroll;
    overflow-y: hidden;
   }
`

export const Caption = styled.div`
 
  text-align: center;
  margin-bottom: 100px;
`
