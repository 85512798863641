import * as htmlToImage from 'html-to-image'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Colors, FormatJSONText, SDG, Typography } from '../../../styles'
import { Caption, Graph, Map, Row } from './styles'

const Migration = ({ country, countryURL, emigrants, immigrants, text }) => {
  const emigrantsGraph = '../html-graphs/' + countryURL + '-emigrants.html'
  const emigrantsPrintGraph = '../svg-graphs/' + countryURL + '-emigrants.svg'
  const immigrantsGraph = '../svg-graphs/' + countryURL + '-immigrants.svg'
  const diaspora = '../html-graphs/' + countryURL + '-map.html'
  const reverseDiaspora = '../html-graphs/' + countryURL + '-reverse-map.html'

  useEffect(() => {
    setTimeout(() => {
      const map = document.getElementById('map')
      const iframe = document.getElementById('diaspora')
      const leafletMap = iframe.contentWindow.document.getElementsByTagName('body')[0]

      htmlToImage.toSvg(leafletMap)
        .then(function (dataUrl) {
          const img = new Image()
          img.src = dataUrl
          img.id = 'svgMap'
          img.classList.add('print-img')
          map.appendChild(img)
        })
        .catch(e => {
          console.log(e)
        })
    }, 500)
  }, [])

  return (
    <div>
      <Typography.Header style={{ marginTop: '0px' }}>{FormatJSONText(text.title, country)}</Typography.Header>
      <Typography.TextContainer bottom='30px'>
        <Typography.Body>{FormatJSONText(text.description, country)}</Typography.Body>
      </Typography.TextContainer>
      <Row bottom='80px' splitWidth={1175} >
        <div>
          <Typography.Body size='18px'>
            <b>{emigrants.value}</b>{FormatJSONText(text.emigrantsGraph.label1, country)}<br/>
            {text.emigrantsGraph.label2}<b>{emigrants.pct}%</b>{FormatJSONText(text.emigrantsGraph.label3, country)}
          </Typography.Body>
          <Typography.Body size='18px'><b>{emigrants.male}%</b>{text.emigrantsGraph.label4}<b>{emigrants.female}%</b>{text.emigrantsGraph.label5}</Typography.Body>
          <Graph>
            <iframe cursor="default" width="100%" height="100%" src={emigrantsGraph}/>
          </Graph>
          <img src={emigrantsPrintGraph} width='65%' className="print-img"/>
          <div style={{ marginTop: '-25px', textAlign: 'center' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.emigrantsGraph.title}</Typography.Header3>
            <Typography.Source>data source: <b>{text.emigrantsGraph.source}</b></Typography.Source>
            <div style={{ marginTop: '-7px' }}>
              <SDG.Button color={Colors.PINK}>
                <Typography.Body color={Colors.WHITE} size='14px'>SDG 10.7</Typography.Body>
              </SDG.Button>
              <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
                <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
              </SDG.Button>
            </div>
          </div>
        </div>
        <div>
          <Typography.Body size='18px'>
            <b>{immigrants.value}</b>{FormatJSONText(text.immigrantsGraph.label1, country)}<br/>
            {text.immigrantsGraph.label2}<b>{immigrants.pct}%</b>{FormatJSONText(text.immigrantsGraph.label3, country)}
          </Typography.Body>
          <Typography.Body size='18px'><b>{immigrants.male}%</b>{text.immigrantsGraph.label4}<b>{immigrants.female}%</b>{text.immigrantsGraph.label5}</Typography.Body>
          <img src={immigrantsGraph} width='90%' style={{ marginBottom: '10px' }}/>
          <div style={{ marginTop: '0px', textAlign: 'center' }}>
            <Typography.Header3 style={{ marginBottom: '-10px' }}>{text.immigrantsGraph.title}</Typography.Header3>
            <Typography.Source>data source: <b>{text.immigrantsGraph.source}</b></Typography.Source>
            <div style={{ marginTop: '-7px' }}>
              <SDG.Button color={Colors.PINK}>
                <Typography.Body color={Colors.WHITE} size='14px'>SDG 10.7</Typography.Body>
              </SDG.Button>
              <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
                <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
              </SDG.Button>
            </div>
          </div>
        </div>
      </Row>

      <Typography.Header style={{ marginTop: '0px' }}>{FormatJSONText(text.diaspora.title, country)}</Typography.Header>
      <Typography.TextContainer bottom='30px'>
        <Typography.Body>{FormatJSONText(text.diaspora.description, country)}</Typography.Body>
      </Typography.TextContainer>

      <Row splitWidth={1175} >
        <div>
          <Typography.Header2 bottom='25px'>{FormatJSONText(text.diaspora.diasporaGraph.title, country)}</Typography.Header2>
          <Map id="map">
            <iframe cursor="default" width="100%" height="100%" src={diaspora} id="diaspora"/>
          </Map>
          <Caption>
            <Typography.Source>data source: <b>{text.diaspora.diasporaGraph.source}</b></Typography.Source>
            <div>
              <SDG.Button color={Colors.PINK}>
                <Typography.Body color={Colors.WHITE} size='14px'>SDG 10.7</Typography.Body>
              </SDG.Button>
              <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
                <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
              </SDG.Button>
            </div>
          </Caption>
        </div>
        <div>
          <Typography.Header2 bottom='25px'>{FormatJSONText(text.diaspora.reverseDiasporaGraph.title, country)}</Typography.Header2>
          <Map id="map">
            <iframe cursor="default" width="100%" height="100%" src={reverseDiaspora} id="diaspora"/>
          </Map>
          <Caption>
            <Typography.Source>data source: <b>{text.diaspora.reverseDiasporaGraph.source}</b></Typography.Source>
            <div>
              <SDG.Button color={Colors.PINK}>
                <Typography.Body color={Colors.WHITE} size='14px'>SDG 10.7</Typography.Body>
              </SDG.Button>
              <SDG.Button color={Colors.DKBLUE} style={{ marginLeft: '5px' }}>
                <Typography.Body color={Colors.WHITE} size='14px'>GCM 1</Typography.Body>
              </SDG.Button>
            </div>
          </Caption>
        </div>
      </Row>
    </div>
  )
}

Migration.propTypes = {
  country: PropTypes.string,
  countryURL: PropTypes.string,
  emigrants: PropTypes.string,
  immigrants: PropTypes.string,
  text: PropTypes.object
}

export default Migration
