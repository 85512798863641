import { Document, Font, Image, Line, Page, PDFViewer, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { CapitalizeString, FormatJSONText } from '../../styles'
import { DKBLUE, LTBLUE, PINK, WHITE } from '../../styles/Colors'
import HeadlineNums from '../headline_nums.json'
import TextData from '../text.json'

const trend = (value) => {
  if (value.trend === 'going down') {
    return (
      <View style={[styles.button, { backgroundColor: DKBLUE, width: '80px', marginBottom: 8, paddingBottom: 2 }]}>
        <Text style={styles.buttonText}>
          going down from {value.startYear}
        </Text>
      </View>
    )
  } else {
    return (
      <View style={[styles.button, { backgroundColor: LTBLUE, width: '80px', marginBottom: 8, paddingBottom: 2 }]}>
        <Text style={styles.buttonText}>
          going up from {value.startYear}
        </Text>
      </View>
    )
  }
}

const sdg = (value, color) => {
  return (
    <View style={[styles.button, { backgroundColor: color, width: 70, marginRight: 5 }]}>
      <Text style={styles.buttonText}>
        {value}
      </Text>
    </View>
  )
}

const embolden = (string) => {
  return <Text style={{ fontWeight: 'bold' }}>{string}</Text>
}

const strikethrough = (
  <Svg height="5" style={{ marginTop: 5, marginBottom: 5 }}>
    <Line x1="0" x2="1000" y1="0" y2="0" stroke="black"/>
  </Svg>
)

const Report = ({ match }) => {
  const slug = match.params.slug

  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState('')
  const [countryData, setCountryData] = useState({})

  const getCountryData = () => {
    const name = CapitalizeString(slug.replaceAll('-', ' '))
    setCountry(name)
    for (let i = 0; i < HeadlineNums.length; i++) {
      if (HeadlineNums[i].country === name) {
        setCountryData(HeadlineNums[i])
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    getCountryData()
  }, [])

  if (loading) {
    return <div><p>Loading...</p></div>
  }

  const { education, unemployment, trafficking, idps, returnees, population, immigrants, emigrants, inflows, outflows } = countryData

  const emigrantsUrl = '../png-graphs/' + slug + '-emigrants.png'
  const immigrantsUrl = '../png-graphs/' + slug + '-immigrants.png'
  const mapUrl = '../png-graphs/' + slug + '-map.png'
  const reverseMapUrl = '../png-graphs/' + slug + '-reverse-map.png'

  const populationUrl = '../png-graphs/' + slug + '-population.png'
  const fertilityUrl = '../png-graphs/' + slug + '-fertility.png'

  const educationUrl = '../png-graphs/' + slug + '-dependency.png'

  const gdpUrl = '../png-graphs/' + slug + '-gdp.png'
  const gdpPerCapitaUrl = '../png-graphs/' + slug + '-gdp-per-capita.png'
  const treeMapUrl = '../png-graphs/' + slug + '-tree-map.png'
  const remittancesUrl = '../png-graphs/' + slug + '-remittances.png'

  const migrantsUrl = '../png-graphs/' + slug + '-migrants.png'
  const sectorsUrl = '../png-graphs/' + slug + '-sectors.png'

  const BlueLogo = '../logo-full.png'

  const standardFont = { fontSize: '30pt' }
  const longFont = { fontSize: '25pt' }
  const bosniaFont = { fontSize: '23pt' }
  const headerSize = country.length > 15 ? (country.length > 20 ? bosniaFont : longFont) : standardFont

  return (
    <PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.row}>
            <View style={styles.titlelogo}>
              <Image style={styles.logo} src={BlueLogo} />
            </View>
            <View style={styles.titletext}>
              <Text style={styles.title}>Migration Data Platform for Evidence-Based Regional Development (M-POWERD)</Text>
            </View>
          </View>
          <Text style={styles.country}>{country}</Text>
          <Text style={[styles.header, headerSize]}>{TextData.country.fastFacts.title}</Text>
          {strikethrough}
          <Text style={[{ display: 'inline' }]}>{TextData.country.fastFacts.population}{embolden(population.value)}</Text>
          {trend(population)}
          <Text>{TextData.country.fastFacts.immigrants}{embolden(immigrants.value)}</Text>
          {trend(immigrants)}
          <Text>{TextData.country.fastFacts.emigrants}{embolden(emigrants.value)}</Text>
          {trend(emigrants)}
          <Text style={styles.source}>data source: {embolden(TextData.country.fastFacts.source1)}</Text>
          <View style={[styles.tagsBlock]}>
            {sdg('SDG 10.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
          {strikethrough}
          <Text>{TextData.country.fastFacts.inflows}{embolden('$' + inflows.value)}</Text>
          {trend(inflows)}
          <Text>{TextData.country.fastFacts.outflows}{embolden('$' + outflows.value)}</Text>
          {trend(outflows)}
          <Text style={styles.source}>data source: {embolden(TextData.country.fastFacts.source2)}</Text>
          <View style={[styles.tagsBlock]}>
            {sdg('SDG 17.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 20', DKBLUE)}
          </View>
          {strikethrough}
        </Page>

        <Page style={styles.page}>
          <Text style={[styles.header, headerSize]}>{FormatJSONText(TextData.country.migration.title, country)}</Text>
          <Text>{FormatJSONText(TextData.country.migration.description, country)}</Text>
          <Text style={styles.topLine}>
            {embolden(emigrants.value)}{FormatJSONText(TextData.country.migration.emigrantsGraph.label1, country)}
            {TextData.country.migration.emigrantsGraph.label2}{embolden(emigrants.pct + '%')}{FormatJSONText(TextData.country.migration.emigrantsGraph.label3, country)}
            {embolden(emigrants.male + '%')}{TextData.country.migration.emigrantsGraph.label4}{embolden(emigrants.female + '%')}{TextData.country.migration.emigrantsGraph.label5}
          </Text>
          <Image style={styles.image} src={emigrantsUrl}/>
          <Text style={styles.center}>{TextData.country.migration.emigrantsGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.migration.emigrantsGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('SDG 10.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Text style={styles.topLine}>
            {embolden(immigrants.value)}{FormatJSONText(TextData.country.migration.immigrantsGraph.label1, country)}
            {TextData.country.migration.immigrantsGraph.label2}{embolden(immigrants.pct + '%')}{FormatJSONText(TextData.country.migration.immigrantsGraph.label3, country)}
            {embolden(immigrants.male + '%')}{TextData.country.migration.immigrantsGraph.label4}{embolden(immigrants.female + '%')}{TextData.country.migration.immigrantsGraph.label5}
          </Text>
          <Image style={styles.image} src={immigrantsUrl}/>
          <Text style={styles.center}>{TextData.country.migration.immigrantsGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.migration.immigrantsGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('SDG 10.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Text style={[styles.header, headerSize]}>{TextData.country.migration.diaspora.title}</Text>
          <Text>{FormatJSONText(TextData.country.migration.diaspora.description, country)}</Text>

          <Text>{embolden(FormatJSONText(TextData.country.migration.diaspora.diasporaGraph.title, country))}</Text>
          <Image style={[styles.image, { width: '100%' }]} src={mapUrl}/>
          <Text style={styles.source}>data source: {embolden(TextData.country.migration.diaspora.diasporaGraph.source)}</Text>
          <View style={styles.tagsBlock}>
            {sdg('SDG 10.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Text>{embolden(FormatJSONText(TextData.country.migration.diaspora.reverseDiasporaGraph.title, country))}</Text>
          <Image style={[styles.image, { width: '100%' }]} src={reverseMapUrl}/>
          <Text style={styles.source}>data source: {embolden(TextData.country.migration.diaspora.reverseDiasporaGraph.source)}</Text>
          <View style={[styles.tagsBlock, { marginBottom: '50px' }]}>
            {sdg('SDG 10.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>

          <View style={styles.row}>
            <View style={[styles.column, styles.contentBox, { paddingLeft: 5, paddingRight: 5 }]}>
              <Text>{TextData.country.forcedMigration.trafficking.title}</Text>
              <Text style={styles.contentBoxNumber}>{trafficking} victims</Text>
              <Text>{FormatJSONText(TextData.country.forcedMigration.trafficking.description, country)}</Text>
              <Text>data source: {embolden(TextData.country.forcedMigration.trafficking.source)}</Text>
              <View style={[styles.tagsBlock, styles.centerSelf]}>
                {sdg('GCM 1', DKBLUE)}
                {sdg('GCM 10', DKBLUE)}
              </View>
            </View>

            <View style={[styles.column, styles.contentBox, { marginLeft: 5, marginRight: 5, paddingLeft: 5, paddingRight: 5 }]}>
              <Text>{TextData.country.forcedMigration.displaced.title}</Text>
              <Text style={[styles.contentBoxNumber]}>{idps}</Text>
              <Text>{FormatJSONText(TextData.country.forcedMigration.displaced.description, country)}</Text>
              <Text>data source: {embolden(TextData.country.forcedMigration.displaced.source)}</Text>
              <View style={[styles.tagsBlock, { marginLeft: 4 }]}>
                {sdg('GCM 1', DKBLUE)}
                {sdg('GCM 2', DKBLUE)}
                {sdg('GCM 7', DKBLUE)}
              </View>
            </View>

            <View style={[styles.column, styles.contentBox, { paddingLeft: 5, paddingRight: 5 }]}>
              <Text>{TextData.country.forcedMigration.returnees.title}</Text>
              <Text style={styles.contentBoxNumber}>{returnees}</Text>
              <Text>{FormatJSONText(TextData.country.forcedMigration.returnees.description, country)}</Text>
              <Text>data source: {embolden(TextData.country.forcedMigration.returnees.source)}</Text>
              <View style={[styles.tagsBlock, styles.centerSelf]}>
                {sdg('GCM 1', DKBLUE)}
                {sdg('GCM 10', DKBLUE)}
              </View>
            </View>
          </View>
        </Page>

        <Page style={styles.page}>
          <Text style={[styles.header, headerSize]}>{FormatJSONText(TextData.country.population.title, country)}</Text>
          <Text>{FormatJSONText(TextData.country.population.description, country)}</Text>

          <Text style={styles.topLine}>{TextData.country.population.populationGraph.label}{embolden(population.value)}</Text>
          <Image style={styles.image} src={populationUrl}/>
          <Text style={styles.center}>{TextData.country.population.populationGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.population.populationGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Text style={styles.topLine}>{embolden(TextData.country.population.fertilityGraph.labelBold)}{TextData.country.population.fertilityGraph.label}</Text>
          <Image style={styles.image} src={fertilityUrl}/>
          <Text style={styles.center}>{TextData.country.population.fertilityGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.population.fertilityGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('SDG 3.7', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Text style={[styles.header, headerSize, { marginBottom: '0px' }]}>{TextData.country.education.title}</Text>
          <View style={[styles.tagsBlock, { marginBottom: '10px' }]}>
            {sdg('SDG 4', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
          <Text>{FormatJSONText(TextData.country.education.description, country)}</Text>
          <View style={[styles.contentBox, { width: '65%' }]}>
            <Text style={styles.center}>{TextData.country.education.box.title}</Text>
            <Text style={styles.topLine}>{embolden(education + '% ')}{TextData.country.education.box.label}</Text>
            <Text style={[styles.source, styles.topLine]}>data source: {embolden(TextData.country.education.box.source)}</Text>
          </View>

          <Text style={[styles.header, headerSize, { marginTop: '20px', marginBottom: '0px' }]}>{TextData.country.dependency.title}</Text>
          <View style={[styles.tagsBlock, { marginBottom: '10px' }]}>
            {sdg('SDG 8', PINK)}
            {sdg('GCM 1', DKBLUE)}
          </View>
          <Text>{FormatJSONText(TextData.country.dependency.description, country)}</Text>
          <Image style={[styles.image, { width: '100%' }]} src={educationUrl}/>
          <Text style={styles.center}>{TextData.country.dependency.graph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.dependency.graph.source)}</Text>
        </Page>

        <Page style={styles.page}>
          <Text style={[styles.header, headerSize]}>{FormatJSONText(TextData.country.economy.title, country)}</Text>
          <Text>{FormatJSONText(TextData.country.economy.description, country)}</Text>

          <Image style={styles.image} src={gdpUrl}/>
          <Text style={styles.center}>{TextData.country.economy.gdpGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.economy.gdpGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 19', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Image style={styles.image} src={gdpPerCapitaUrl}/>
          <Text style={styles.center}>{TextData.country.economy.gdpPerCapitaGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.economy.gdpPerCapitaGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 19', DKBLUE)}
          </View>

          <Image style={[styles.image, { marginTop: '50px' }]} src={remittancesUrl}/>
          <Text style={styles.center}>{TextData.country.economy.remittanceGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.economy.remittanceGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 20', DKBLUE)}
          </View>
        </Page>

        <Page style={styles.page}>
          <Image style={styles.image} src={treeMapUrl}/>
          <Text style={styles.center}>{TextData.country.economy.treeMap.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.economy.treeMap.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 20', DKBLUE)}
          </View>

          <Image style={[styles.image, { marginTop: '15px' }]} src={migrantsUrl}/>
          <Text style={styles.center}>{TextData.country.economy.workingAgeGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.economy.workingAgeGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 19', DKBLUE)}
          </View>

          <View style={[styles.contentBox, { width: '100%', marginTop: '25px' }]}>
            <Text style={styles.smallTitle}>{TextData.country.economy.didYouKnow.title}</Text>
            <Text>{FormatJSONText(TextData.country.economy.didYouKnow.label, country)}</Text>
          </View>
        </Page>

        <Page style={styles.page}>
          <Image style={styles.image} src={sectorsUrl}/>
          <Text style={styles.center}>{TextData.country.economy.sectorGraph.title}</Text>
          <Text style={[styles.center, styles.source]}>data source: {embolden(TextData.country.economy.sectorGraph.source)}</Text>
          <View style={[styles.centerSelf, styles.tagsBlock]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 19', DKBLUE)}
          </View>

          <View style={[styles.row, { marginTop: '50px' }]}>
            <View style={[styles.column, styles.contentBox]}>
              <Text>{FormatJSONText(TextData.country.economy.unemployment.country, country).toUpperCase()}</Text>
              <Text style={styles.contentBoxNumber}>{unemployment}%</Text>
            </View>
            <View style={[styles.column, styles.contentBox, { marginLeft: 2, marginRight: 2 }]}>
              <Text>{TextData.country.economy.unemployment.seeeca}</Text>
              <Text style={styles.contentBoxNumber}>10.1%</Text>
            </View>
            <View style={[styles.column, styles.contentBox]}>
              <Text>{TextData.country.economy.unemployment.world}</Text>
              <Text style={styles.contentBoxNumber}>6.5%</Text>
            </View>
          </View>
          <Text style={[styles.center, { marginTop: '-10px' }]}>{TextData.country.economy.unemployment.title}</Text>
          <Text style={[styles.source, styles.centerSelf]}>data source: {embolden(TextData.country.economy.unemployment.source)}</Text>
          <View style={[styles.tagsBlock, styles.centerSelf, { marginBottom: 50 }]}>
            {sdg('GCM 1', DKBLUE)}
            {sdg('GCM 19', DKBLUE)}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

Font.register(
  {
    family: 'Work Sans',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8JoI3ZKyHaQQ.woff',
        fontWeight: 'normal'
      },
      {
        src: 'https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QBi8JoI3ZKyHaQQ.woff',
        fontWeight: 'bold'
      }
    ]
  }
)

const styles = StyleSheet.create({
  title: {
    fontSize: '28pt',
    fontWeight: 'bold',
    fontFamily: 'Work Sans',
    color: DKBLUE,
    marginBottom: 60
  },
  smallTitle: {
    fontSize: '18pt',
    fontWeight: 'bold',
    fontFamily: 'Work Sans',
    color: '#000000',
    marginBottom: 20
  },
  topLine: {
    marginTop: 8
  },
  bottomLine: {
    marginBottom: 8
  },
  country: {
    fontSize: '48pt',
    fontWeight: 'bold',
    fontFamily: 'Work Sans',
    color: DKBLUE,
    marginBottom: 50
  },
  header: {
    fontSize: '30pt',
    fontWeight: 'bold',
    marginBottom: 20
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Work Sans',
    fontSize: '12pt'
  },
  button: {
    display: 'flex',
    padding: '0px 5px',
    borderRadius: '8px',
    textAlign: 'center'
  },
  buttonText: {
    color: WHITE,
    fontFamily: 'Work Sans',
    fontSize: '12pt'
  },
  source: {
    fontSize: '10pt',
    fontFamily: 'Work Sans'
  },
  center: {
    textAlign: 'center'
  },
  centerSelf: {
    alignSelf: 'center'
  },
  image: {
    width: '60%',
    alignSelf: 'center',
    margin: 10
  },
  logo: {
    width: '100%',
    alignSelf: 'left',
    marginLeft: -15
  },
  tagsBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5
  },
  contentBox: {
    backgroundColor: '#D8E0FD',
    marginTop: 10,
    marginBottom: 20,
    padding: 15,
    borderRadius: '8px',
    textAlign: 'center'
  },
  contentBoxNumber: {
    fontWeight: 'bold',
    fontSize: '24pt',
    margin: 12
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
    // flexWrap: 'wrap'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
    // flexWrap: 'wrap'
  },
  titlelogo: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    alignItems: 'center'
  },
  titletext: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    alignItems: 'center'
  }
})

Report.propTypes = {
  match: PropTypes.object
}

export default Report
