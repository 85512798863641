import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import Country from './pages/Country'
import Home from './pages/Home'
import Report from './pages/pdf'

function App () {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact>
            <Home/>
          </Route>
          <Route path="/country/:slug" component={Country} />
          <Route path="/pdf/:slug" component={Report} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
